import './App.css';
import Terminal from './Terminal';

const App = () => {
  return (
    <div className="App">
      <Terminal />
    </div>
  );
};

export default App;
